import React, { useState, useEffect, useRef } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, isEmpty, orderBy } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { ToggleButton } from 'primereact/togglebutton';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { displayErrors, formataDataMoment, converteData } from '../../../../util/diversos';
import { Input155px, Input200px, Input250px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import axiosAdmin from '../../../../services/axiosAdmin';

import ConsultaProduto from '../../produto/consultaProduto';
import ConsultaParticipante from '../../participante/consultaParticipante';
import ConsultaTecnico from '../../tecnico/consultaTecnico';

import { fillColumns } from '../../../../components/table';

export default function CadastroOrdemServicoAparelho({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [idUsuario, setIdUsuario] = useState(get(store.getState().auth.user, 'id', null));

  // console.log('user: ', store.getState().auth.user);

  const [permitirConsultarItem, setPermitirConsultarItem] = useState(true);
  const [permitirCadastrarItem, setPermitirCadastrarItem] = useState(true);
  const [permitirEditarItem, setPermitirEditarItem] = useState(true);
  const [permitirExcluirItem, setPermitirExcluirItem] = useState(true);

  const id = get(payloadCadastro, 'id', 0);
  const tipoUsuario = get(store.getState().auth.user, 'tipoUsuario', null);
  const emailOperador = get(store.getState().auth.user, 'email', null);
  const oficinaOperador = get(store.getState().auth.user, 'operadorOficina', null);

  // eslint-disable-next-line no-unused-vars
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [cnpjEmpresa, setCnpjEmpresa] = useState(get(payloadCadastro, 'cnpj', ''));
  // eslint-disable-next-line no-unused-vars
  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [disabledNumero, setDisabledNumero] = useState(false);

  const [numero, setNumero] = useState(1);
  const [dataAbertura, setDataAbertura] = useState(new Date());
  const [dataEntrega, setDataEntrega] = useState(null);
  const [dataPrevisaoEntrega, setDataPrevisaoEntrega] = useState(null);
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState('');
  const [telefoneCliente, setTelefoneCliente] = useState('');
  const [tipo, setTipo] = useState('SERVICO');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [imei, setImei] = useState('');
  const [garantiaDias] = useState('90');
  const [slotChip, setSlotChip] = useState(false);
  const [condicaoTeste, setCondicaoTeste] = useState(false);
  const [aparelhoLiga, setAparelhoLiga] = useState(false);
  const [aparelhoMolhado, setAparelhoMolhado] = useState(false);
  const [aparelhoCarrega, setAparelhoCarrega] = useState(false);
  const [queixas, setQueixas] = useState('');
  const [codigoTecnico, setCodigoTecnico] = useState('');
  const [idTecnico, setIdTecnico] = useState('');
  const [nomeTecnico, setNomeTecnico] = useState('');
  const [avaliacaoTecnica, setAvaliacaoTecnica] = useState('');
  const [observacao, setObservacao] = useState('');
  const [valorFinal, setValorFinal] = useState(0);
  const [situacao, setSituacao] = useState('ABERTA');

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    // eslint-disable-next-line no-unused-expressions
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

    return [htmlElRef, setFocus];
  };

  const [inputProdutoRegistroFocus, setInputProdutoRegistroFocus] = UseFocus();
  const [inputQuantidadeFocus, setInputQuantidadeFocus] = UseFocus();
  const [inputValorUnitarioFocus, setInputValorUnitarioFocus] = UseFocus();
  const [buttonOkItem, setButtonOkItem] = UseFocus();
  const [buttonInclusaoItem, setButtonInclusaoItem] = UseFocus();

  // eslint-disable-next-line no-unused-vars
  const [produtoConsulta, setProdutoConsulta] = useState(null);
  const [listaItem, setListaItem] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [itemIdProduto, setItemIdProduto] = useState(0);
  const [itemRegistroProduto, setItemRegistroProduto] = useState(0);
  const [itemDescricao, setItemDescricao] = useState('');
  const [itemUnidadeMedida, setItemUnidadeMedida] = useState('');
  const [itemQuantidade, setItemQuantidade] = useState(0);
  const [itemValorUnitario, setItemValorUnitario] = useState(0);
  const [editandoItem, setEditandoItem] = useState(0);

  const [showCadastroItem, setShowCadastroItem] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);
  const [showConsultaProduto, setShowConsultaProduto] = useState(false);

  const [columnsSetupItem] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'itemRegistroProduto', header: 'Registro', sort: true },
    { field: 'itemDescricao', header: 'Descrição', sort: true },
    { field: 'itemQuantidade', header: 'Quantidade', sort: true },
    { field: 'itemValorUnitario', header: 'Valor Unitário', sort: false, moeda: true },
    { field: 'itemValorTotal', header: 'Total', sort: false, moeda: true },
  ]);

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaTecnico, setShowConsultaTecnico] = useState(false);

  const [listaTipo] = useState([
    { label: 'Serviço', value: 'SERVICO' },
    { label: 'Garantia', value: 'GARANTIA' },
    { label: 'Orçamento', value: 'ORCAMENTO' },
  ]);

  const [listaSituacao] = useState([
    { label: 'Aberta', value: 'ABERTA' },
    { label: 'Fechada', value: 'FECHADA' },
    { label: 'Cancelada', value: 'CANCELADA' },
  ]);

  async function setUltimoNumero() {
    if (cnpjEmpresa !== null && cnpjEmpresa !== '') {
      await axiosAdmin.get(`/osaparelho/ultimonumero?cnpjEmpresa=${cnpjEmpresa}&oficinaOperador=${oficinaOperador.id}`).then(res => {
        if (res.data !== null) {
          const proximoNumero = parseInt(res.data, 10) + 1;
          // eslint-disable-next-line no-unused-expressions
          proximoNumero === 1 ? setDisabledNumero(false) : setDisabledNumero(true);
          setNumero(proximoNumero.toString());
        }
      });
    }
  }

  const atualizaValorFinal = () => {
    let totalFinal = 0;
    listaItem.forEach(el => {
      totalFinal += +el.itemValorTotal;
    });
    setValorFinal(totalFinal);
  };

  useEffect(() => {
    atualizaValorFinal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaItem]);

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoConsultarItem = listaGrupoAcessoBody.filter(el => el.modulo === 'ConsultarItemOrdemServicoAparelho');
      const permissaoCadastrarItem = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarItemOrdemServicoAparelho');
      const permissaoEditarItem = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarItemOrdemServicoAparelho');
      const permissaoExcluirItem = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirItemOrdemServicoAparelho');

      if (permissaoConsultarItem.length <= 0) {
        setPermitirConsultarItem(false);
      }

      if (permissaoCadastrarItem.length <= 0) {
        setPermitirCadastrarItem(false);
      }

      if (permissaoEditarItem.length <= 0) {
        setPermitirEditarItem(false);
      }

      if (permissaoExcluirItem.length <= 0) {
        setPermitirExcluirItem(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/osaparelho/${id}`);

        // console.log('data: ', data);

        const _data = converteData(data.data, true);
        const _dataEntrega = data.dataEntrega ? converteData(data.dataEntrega, true) : null;
        const _dataPrevisaoEntrega = data.dataPrevisaoEntrega ? converteData(data.dataPrevisaoEntrega, true) : null;

        setNumero(data.numero);
        setIdUsuario(data.idUsuario);
        setDataAbertura(_data);
        setDataEntrega(_dataEntrega);
        setDataPrevisaoEntrega(_dataPrevisaoEntrega);
        setValorOrcamento(data.valorOrcamento);
        setIdCliente(data.cliente ? data.cliente.id : null);
        setNomeCliente(data.nomeCliente);
        setTelefoneCliente(data.telefoneCliente);
        setTipo(data.tipo);
        setMarca(data.marca);
        setModelo(data.modelo);
        setImei(data.imei);
        setSlotChip(data.slotChip);
        setCondicaoTeste(data.condicaoTeste);
        setAparelhoLiga(!data.aparelhoLiga);
        setAparelhoMolhado(data.aparelhoMolhado);
        setAparelhoCarrega(data.aparelhoCarrega);
        setQueixas(data.queixas.trim());
        setAvaliacaoTecnica(data.avaliacaoTecnica.trim());
        setNomeTecnico(get(data.tecnico, 'nome', ''));
        setIdTecnico(get(data.tecnico, 'id', null));
        setCodigoTecnico(get(data.tecnico, 'codigo', ''));
        setObservacao(data.observacao ? data.observacao.trim() : '');
        setValorFinal(data.valorFinal);
        setSituacao(data.situacao);

        setDisabledNumero(true);

        const _listaItem = [];
        data.listaOsAparelhoItem.forEach(el => {
          _listaItem.push({
            item: el.item,
            idProduto: el.produto.id,
            itemRegistroProduto: el.produto.registro,
            itemDescricao: el.produto.descricao.trim(),
            itemQuantidade: el.quantidade,
            itemUnidadeMedida: el.produto.unidadeMedida.sigla,
            itemValorUnitario: el.valorUnitario,
            itemValorTotal: (+el.quantidade * +el.valorUnitario),
          });
        });

        const listaItemOrdenada = orderBy(_listaItem, 'itemDescricao', 'asc');

        setListaItem(listaItemOrdenada);

        if (tipoUsuario === 'EMPRESA') {
          setCnpjEmpresa(get(data.empresa, 'cnpj', null));
        } else {
          setIdEmpresa(get(data.empresa, 'id', null));
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      if (tipoUsuario === 'EMPRESA') {
        try {
          setIsLoading(true);

          const params = `/usuario/${idUsuario}`;

          const response = await axiosAdmin.get(params);

          const _listaEmpresas = get(response.data, 'listaEmpresas', []);

          const listDropdown = [];

          let cnpjPreenchido = false;
          let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            cnpjPreenchido = true;
          }

          _listaEmpresas.forEach(element => {
            if (!cnpjPreenchido) {
              setCnpjEmpresa(element.cnpj);
              setIdEmpresa(element.id);
              _cnpjEmpresaStore = element.cnpj;

              cnpjPreenchido = true;
            }

            listDropdown.push(
              {
                label: element.razaoSocial,
                value: element.cnpj,
                id: element.id,
              });
          });

          // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
          if (!isEmpty(cnpjEmpresa)) {
            setCnpjEmpresa(cnpjEmpresa);
          }

          listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

          setListaEmpresas(listDropdown);
        } catch (err) {
          displayErrors(err, dispatch, navigate, '/', '');
        } finally {
          setIsLoading(false);
        }
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (cnpjEmpresa !== null && cnpjEmpresa !== '' && id <= 0) {
      setUltimoNumero(cnpjEmpresa);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa]);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      let _dataAbertura = null;
      if (dataAbertura) {
        _dataAbertura = formataDataMoment(dataAbertura, '-', true);
      }

      let _dataEntrega = null;
      if (dataEntrega) {
        _dataEntrega = formataDataMoment(dataEntrega, '-', true);
      }

      let _dataPrevisaoEntrega = null;
      if (dataPrevisaoEntrega) {
        _dataPrevisaoEntrega = formataDataMoment(dataPrevisaoEntrega, '-', true);
      }

      const itens = [];
      let item = 1;
      const listaItemOrdenada = orderBy(listaItem, 'itemDescricao', 'asc');
      listaItemOrdenada.forEach(el => {
        itens.push({
          item: item++,
          idProduto: el.idProduto,
          quantidade: el.itemQuantidade,
          valorUnitario: el.itemValorUnitario,
        });
      });

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        numero,
        data: _dataAbertura,
        dataEntrega: _dataEntrega,
        dataPrevisaoEntrega: _dataPrevisaoEntrega,
        valorOrcamento,
        idCliente,
        nomeCliente,
        telefoneCliente,
        tipo,
        marca,
        modelo,
        imei,
        garantiaDias,
        slotChip,
        condicaoTeste,
        aparelhoLiga,
        aparelhoMolhado,
        aparelhoCarrega,
        queixas,
        avaliacaoTecnica,
        idTecnico,
        observacao,
        valorFinal,
        listaOsAparelhoItem: itens,
        situacao,
        emailOperador,
        idOficinaOperador: oficinaOperador ? oficinaOperador.id : null,
      };

      // console.log('shippingdata: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = await axiosAdmin.put(`/osaparelho/${id}`, shippingData);
        toast.success('OS Aparelho atualizada com sucesso');
      } else {
        payloadDataTableList = await axiosAdmin.post('/osaparelho', shippingData);
        toast.success('OS Aparelho cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (valorOrcamento <= 0) {
      toast.info('Valor do orçamento deve ser informado');
      return false;
    }

    if (nomeCliente.length < 1) {
      toast.info('Nome do Cliente deve ser informado');
      return false;
    }

    if (marca.length === 0) {
      toast.info('Marca deve ser informada');
      return false;
    }

    if (modelo.length === 0) {
      toast.info('Modelo deve ser informado');
      return false;
    }

    if (queixas.length < 3 || queixas.length > 500) {
      formErros = true;
      toast.error('Queixas devem ter entre 3 a 499 caracteres');
    }

    // eslint-disable-next-line consistent-return
    if (formErros) return;

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdCliente(e.id);
      }
      if (e.razaoSocial) {
        setNomeCliente(e.razaoSocial);
      }
      if (e.telefone_1) {
        setTelefoneCliente(e.telefone_1);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarTecnicoByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdTecnico('');
        setNomeTecnico('');
        return false;
      }

      let params = `/tecnico?idUsuario=${idUsuario}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdTecnico(response.data[0].id);
        setNomeTecnico(response.data[0].nome);
      } else {
        setIdTecnico('');
        setNomeTecnico('');
        toast.info('Nenhum vendedor com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const onHideConsultaTecnico = () => {
    setShowConsultaTecnico(false);
  };

  const childToParentTecnico = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdTecnico(e.id);
      }
      if (e.codigo) {
        setCodigoTecnico(e.codigo);
      }
      if (e.nome) {
        setNomeTecnico(e.nome);
      }
      setShowConsultaTecnico(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const limpaCamposCadastroItem = () => {
    setItemRegistroProduto('');
    setItemDescricao('');
    setItemUnidadeMedida('');
    setItemQuantidade(0);
    setItemValorUnitario(0);
  };

  // eslint-disable-next-line consistent-return
  const handleNovoItem = () => {
    if (!permitirCadastrarItem) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }
    limpaCamposCadastroItem();
    setShowCadastroItem(true);
  };

  // eslint-disable-next-line consistent-return
  const handleConsultarProduto = () => {
    if (!permitirConsultarItem) {
      toast.info('Usuário não autorizado a consultar produto.');
      return false;
    }
    setShowConsultaProduto(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEditItem = (e) => {
    e.preventDefault();

    if (!permitirEditarItem) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return false;
      }

      setEditandoItem(item);

      limpaCamposCadastroItem();

      setItemIdProduto(_itemSelecionado.idProduto);
      setItemRegistroProduto(_itemSelecionado.itemRegistroProduto);
      setItemDescricao(_itemSelecionado.itemDescricao);
      setItemUnidadeMedida(_itemSelecionado.itemUnidadeMedida);
      setItemQuantidade(_itemSelecionado.itemQuantidade);
      setItemValorUnitario(_itemSelecionado.itemValorUnitario);

      setShowCadastroItem(true);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDeleteItem = (e) => {
    e.preventDefault();

    if (!permitirExcluirItem) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return false;
      }

      setShowConfirmDeleteItem(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const confirmDeleteItem = () => {
    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _listaItem = listaItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === item));

      if (index >= 0) {
        _listaItem.splice(index, 1);

        _listaItem.forEach(element => {
          if (element.item > item) {
            const _index = _listaItem.indexOf(element);

            _listaItem[_index].item = _listaItem[_index].item - 1;
          }
        });

        setListaItem(_listaItem);

        setItemIdProduto(0);
        setShowConfirmDeleteItem(false);
        atualizaValorFinal();
      }
    }
  };

  const cancelDeleteItem = () => {
    setShowConfirmDeleteItem(false);
  };

  const confirmDialogFooterItem = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItem} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItem} />
    </div>
  );

  // eslint-disable-next-line consistent-return
  const handleOkItem = async () => {
    if (itemIdProduto <= 0) {
      toast.warning('Produto deve ser informado');
      return false;
    }

    if (itemDescricao === '') {
      toast.warning('Produto deve ser informado');
      return false;
    }

    if (itemQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      setInputQuantidadeFocus();
      return false;
    }

    if (itemValorUnitario <= 0) {
      toast.warning('Valor deve ser informado');
      setInputValorUnitarioFocus();
      return false;
    }

    const _listaItem = listaItem;

    let item = 0;
    listaItem.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItem && editandoItem > 0) {
      item = editandoItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === editandoItem));

      if (index >= 0) {
        _listaItem.splice(index, 1);
      }
    }

    _listaItem.push({
      item,
      idProduto: itemIdProduto,
      itemRegistroProduto,
      itemDescricao: itemDescricao.trim(),
      itemQuantidade,
      itemUnidadeMedida,
      itemValorUnitario: +itemValorUnitario,
      itemValorTotal: +itemQuantidade * +itemValorUnitario,
    });

    const listaItemOrdenada = orderBy(_listaItem, 'item', 'asc');

    setListaItem([]);
    setListaItem(listaItemOrdenada);

    limpaCamposCadastroItem();
    setShowCadastroItem(false);
    setButtonInclusaoItem();
  };

  const onHideCadastroItem = () => {
    setShowCadastroItem(false);
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const verificarItemListaItem = (idProduto) => {
    let existeItem = false;
    listaItem.forEach(element => {
      if (idProduto === element.idProduto) {
        existeItem = true;
      }
    });

    return existeItem;
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarProdutoByIdUsuarioEmpresaDestino(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setItemDescricao('');
        return false;
      }

      let params = `/produto/search?idUsuario=${idUsuario}`;

      params = `${params}&registro=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (verificarItemListaItem(response.data.content[0].id)) {
        toast.warn('Produto já incluído na ordem de serviço');
        limpaCamposCadastroItem();
        return false;
      }

      if (response.data) {
        setProdutoConsulta(response.data.content[0]);
        setItemIdProduto(response.data.content[0].id);
        setItemDescricao(response.data.content[0].descricao);
        setItemUnidadeMedida(response.data.content[0].unidadeMedida.sigla);
        setItemValorUnitario(response.data.content[0].precoVenda);
        setInputQuantidadeFocus();
      } else {
        limpaCamposCadastroItem();
        toast.info('Produto não encontrado');
        setInputProdutoRegistroFocus();
      }
    } catch (err) {
      setItemDescricao('');
      setInputProdutoRegistroFocus();
      toast.info('Produto não encontrado');

      // displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line consistent-return
  const childToParentConsultaProduto = (e) => {
    setProdutoConsulta(e);
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (verificarItemListaItem(idElement)) {
        toast.warn('Produto já incluído na movimentação');
        limpaCamposCadastroItem();
        return false;
      }

      if (idUsuario === e.idUsuario) {
        if (e.id) {
          setItemIdProduto(e.id);
          setItemRegistroProduto(e.registro);
          setItemDescricao(e.descricao);
          setItemUnidadeMedida(e.unidadeMedida.sigla);
          setItemValorUnitario(e.precoVenda);
        }
        setShowConsultaProduto(false);
        setInputQuantidadeFocus();
      } else {
        toast.warn('Produto selecionado não pertence a empresa');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const titulo = () => (id ? 'Ordem de Serviço Aparelho - Alteração' : 'Ordem Serviço Aparelho - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Empresas"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} somenteLeitura="true" />
      </Dialog>

      <Dialog
        header="Consulta de Técnico"
        visible={showConsultaTecnico}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaTecnico()}
      >
        <ConsultaTecnico menuMode={false} childToParent={childToParentTecnico} somenteLeitura="true" />
      </Dialog>

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} somenteLeitura />
      </Dialog>

      <Dialog
        header="Inclusão Ítem"
        visible={showCadastroItem}
        style={{ width: '70vw' }}
        onHide={() => onHideCadastroItem()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Produto*
              </Input200px>
              <InputText
                inputRef={inputProdutoRegistroFocus}
                autoFocus
                onFocus={(e) => e.target.select()}
                disabled={editandoItem}
                value={itemRegistroProduto}
                onChange={e => setItemRegistroProduto(e.target.value)}
                onBlur={e => {
                  handleBuscarProdutoByIdUsuarioEmpresaDestino(e.target.value);
                  // setInputQuantidadeFocus();
                }}
                onKeyDown={async (e) => {
                  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    handleBuscarProdutoByIdUsuarioEmpresaDestino(e.target.value);
                  }
                }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={itemDescricao}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Unidade*
              </Input200px>
              <InputText
                disabled
                value={itemUnidadeMedida}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Quantidade*
              </Input200px>
              <InputNumber
                inputRef={inputQuantidadeFocus}
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={4}
                maxFractionDigits={4}
                value={itemQuantidade}
                onValueChange={(e) => setItemQuantidade(e.target.value)}
                onKeyDown={async (e) => {
                  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                    setButtonOkItem();
                  }
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                disabled
                inputRef={inputValorUnitarioFocus}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemValorUnitario}
                onValueChange={e => setItemValorUnitario(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              ref={buttonOkItem}
              type="button"
              className="btn btn-success"
              // title="Confirma Dados do Item"
              onClick={handleOkItem}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItem(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número*
              </Input155px>
              <InputNumber
                disabled={disabledNumero}
                autoFocus
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={numero}
                onValueChange={e => setNumero(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Abertura
              </Input155px>
              <Calendar
                disabled
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataAbertura}
                onChange={(e) => setDataAbertura(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Previsão Entrega
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataPrevisaoEntrega}
                onChange={(e) => setDataPrevisaoEntrega(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Orcamento
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorOrcamento}
                onValueChange={e => setValorOrcamento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                maxLength={40}
                value={nomeCliente}
                onChange={e => setNomeCliente(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Telefone*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputTelefone"
                maxLength={20}
                value={telefoneCliente}
                onChange={e => setTelefoneCliente(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipo}
                value={tipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Marca*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputMarca"
                maxLength={50}
                value={marca}
                onChange={e => setMarca(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modelo*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputModelo"
                maxLength={50}
                value={modelo}
                onChange={e => setModelo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                IMEI
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputImei"
                maxLength={50}
                value={imei}
                onChange={e => setImei(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Situação
              </Input155px>
              <Dropdown
                disabled
                options={listaSituacao}
                value={situacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setSituacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Slot Chip
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={slotChip}
                onChange={(e) => setSlotChip(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Condição de Teste
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={condicaoTeste}
                onChange={(e) => setCondicaoTeste(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Aparelho Molhado
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoMolhado}
                onChange={(e) => setAparelhoMolhado(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Aparelho Liga
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoLiga}
                onChange={(e) => setAparelhoLiga(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Aparelho Carrega
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoCarrega}
                onChange={(e) => setAparelhoCarrega(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cód. Técnico
              </Input155px>
              <InputText
                maxLength={255}
                value={codigoTecnico}
                onChange={e => setCodigoTecnico(e.target.value)}
                onBlur={e => handleBuscarTecnicoByCodigo(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaTecnico(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={nomeTecnico}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Final
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorFinal}
                onValueChange={e => setValorFinal(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Queixas*
              </Input155px>
              <InputTextarea
                rows={1}
                value={queixas}
                onChange={e => setQueixas(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Avaliação / Servico Realizado
              </Input250px>
              <InputTextarea
                rows={1}
                value={avaliacaoTecnica}
                onChange={e => setAvaliacaoTecnica(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Observação
              </Input250px>
              <InputTextarea
                rows={1}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="estamparia-pedido-item">
            <DataTable
              header="Produtos"
              value={listaItem}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="item"
              emptyMessage="Nenhum Item cadastrado"
            >
              {fillColumns(columnsSetupItem)}
            </DataTable>
          </div>
          <br />
          <div className="row form-elo">
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                type="button"
                title="Consultar Produto"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={() => handleConsultarProduto()}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                ref={buttonInclusaoItem}
                type="button"
                title="Inclusão Item"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  setEditandoItem(0);
                  handleNovoItem();
                }}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Alterar Item"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEditItem(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Cancelar Item"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDeleteItem(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItem}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItem}
        onHide={() => setShowConfirmDeleteItem(false)}
      >
        <p>
          Excluir o item?
        </p>
      </Dialog>
    </div>
  );
}
